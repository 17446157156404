/**
 * Main entrypoint file to
 * start the RetailOS React app.
 * This file is unlikely to need much editing.
 */

import { entrypoint, PlatformDependencies, setUserPersonalDetailsConfig } from '@redant/digital-store-client'
import { initalizeCustomDependencies } from './customizations/dependencies'

async function main () {
  try {
    const res = await initalizeCustomDependencies()
    await PlatformDependencies.initializeDependencies(res.overrides)
    await entrypoint()

    // Run after the app starts to overwrite core platform default values
    require('./customizations/applyUiConfigMaps')
    require('./customizations/applySchemaConfig')
  
  } catch (error) {
    console.error('RETAILOS APPLICATION HAS CRASHED:')
    console.error(error)
  }
}

main()
